import { cn } from '@/lib/utils';
import React from 'react';

const CurriculumSprintCard = ({ sprints }) => {
  const getSkillsBySprintCode = (sprintCode) => {
    switch (sprintCode) {
      case 'JS-100':
        return { skills: ['Programming concepts'], projects: [] };

      case 'JS-101':
        return {
          skills: [
            'Data Types',
            'Keywords',
            'Operators',
            'Conditionals',
            'Loops',
            'Arrays and 2D Arrays',
            'Strings',
            'Functions',
          ],
          projects: [],
        };

      case 'FE-101':
        return {
          skills: [
            'HTML',
            'CSS',
            'Flexbox',
            'CSS Grid',
            'Bootstrap',
            'Responsive Design',
            'Git',
            'Linux Basics',
          ],
          projects: [],
        };

      case 'FE-102':
        return {
          skills: [
            'Bootstrap',
            'CSS Positioning',
            'CSS Inheritance',
            'DOM Manipulation',
            'Promises',
            'Async-await',
            'Deployment',
          ],
          projects: ['QTrip'],
        };

      case 'FE-103':
        return {
          skills: [
            'REST APIs',
            'Forms',
            'Fetch API',
            'Local Storage',
            'Debugging',
          ],
          projects: ['QTrip', 'XBoard'],
        };

      case 'FE-201':
        return {
          skills: [
            'Functional Programming',
            'Class-based Components',
            'Lifecycle methods',
            'Functional Components',
            'React Hooks',
            'Controlled Components',
            'React Router',
            'Material UI',
          ],
          projects: ['QKart Frontend'],
        };

      case 'FE-202':
        return {
          skills: [
            'Debouncing',
            'Mocking API calls',
            'Promise Combinators',
            'Advanced Hooks',
            'Custom Hooks',
          ],
          projects: ['QKart Frontend', 'QTify'],
        };

      case 'FE-301':
        return {
          skills: ['Global State Management', 'Redux'],
          projects: [],
        };

      case 'FE-302':
        return {
          skills: [
            'CDN',
            'Performance',
            'Optimization',
            'Server Side Rendering',
          ],
          projects: [],
        };

      case 'NODE-201':
        return {
          skills: [
            'Node.js',
            'Express.js',
            'MongoDB',
            'JOI Data Validation',
            'Authentication',
            'JWT Token',
            'Passport',
          ],
          projects: ['QKart Backend'],
        };

      case 'NODE-202':
        return {
          skills: ['Testing', 'Jest', 'TDD', 'MongoDB Atlas'],
          projects: ['QKart Backend', 'XFlix'],
        };

      case 'JAVA-111':
        return {
          skills: [
            'Java Syntax Basics',
            'Loops',
            'Conditionals',
            'Strings',
            'Arrays',
            'Collections',
            'Debugging 101',
            'Classes and Objects',
          ],
          projects: [],
        };

      case 'JAVA-112':
        return {
          skills: [
            'Constructors',
            'Access Modifiers',
            'Static',
            'Final',
            'OOP Principles',
            'Exception Handling',
            'Problem-solving',
            'GIT',
            'Linux Basics',
          ],
          projects: ['QCalc', 'XQuiz'],
        };

      case 'JAVA-201':
        return {
          skills: [
            'Advanced OOP',
            'Gradle',
            'Streams and Lambda',
            'JSON',
            'Jackson',
          ],
          projects: ['QMoney'],
        };

      case 'JAVA-202':
        return {
          skills: [
            'HTTP',
            'REST API',
            'Unit Testing (JUnit, Mockito)',
            'Debugging',
            'Multithreading',
          ],
          projects: ['QMoney'],
        };

      case 'JAVA-203':
        return {
          skills: [
            'Object Modeling',
            'SOLID Principles',
            'Design Patterns',
            'SQL',
            'Machine Coding Basics',
          ],
          projects: ['QContest'],
        };

      case 'JAVA-301':
        return {
          skills: [
            'MVC',
            'MongoDB',
            'Spring Basics',
            'Lombok',
            'SpringBoot',
            'Spring Data',
            'Redis Cache',
          ],
          projects: ['QEats'],
        };

      case 'JAVA-302':
        return {
          skills: [
            'The Architecture of Web Applications',
            'Deployment',
            'Hibernate',
            'Schema Design',
            'Logging',
            'AWS Basics',
          ],
          projects: ['QEats', 'XMeme'],
        };

      case 'HLD-101':
        return {
          skills: [
            'Micro-Services',
            'Docker',
            'Kafka',
            'CDN',
            'Load Balancer',
            'Deployment',
            'CI/CD',
            'Cloud',
          ],
          projects: [],
        };

      case 'DSA-JS-101':
      case 'DSA-101':
        return {
          skills: [
            'Array',
            '2D Array',
            'String',
            'Math',
            'Recursion',
            'Set',
            'Map',
          ],
          projects: [],
        };

      case 'DSA-JS-102':
      case 'DSA-102':
        return {
          skills: [
            'Array and String-related Patterns',
            'Two Pointers',
            'Sliding Window',
            'Sorting',
            'Searching',
          ],
          projects: [],
        };

      case 'DSA-201':
        return {
          skills: [
            'Stack',
            'Queue',
            'Hash',
            'Linked List',
            'Binary Tree',
            'Binary Search Tree',
          ],
          projects: [],
        };

      case 'DSA-202':
        return {
          skills: [
            'Heap',
            'Backtracking',
            'Greedy',
            'Graph',
            'BFS',
            'DFS',
            'Bipartite',
            'Connected Components',
            'Shortest Path',
            'Spanning Tree',
          ],
          projects: [],
        };

      case 'DSA-203':
        return {
          skills: ['DP', 'Trie', 'Segment Tree', 'Interview Blitz', 'Mocks'],
          projects: [],
        };

      default:
        return {
          skills: [],
          projects: [],
        };
    }
  };

  return (
    <div>
      {sprints.map((sprint) => (
        <div className="">
          <h3 className="text-[14px] text-[#F2FBF7] md:text-[20px]">
            {sprint.sprintCode}
          </h3>
          {getSkillsBySprintCode(sprint.sprintCode)?.skills?.length > 0 && (
            <div>
              <div className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3">
                {getSkillsBySprintCode(sprint.sprintCode)?.skills?.map(
                  (ele, idx) => (
                    <div
                      key={idx}
                      className="rounded-[5px] border border-v5-neutral-300 p-1 px-3 md:border-2"
                    >
                      <h6 className=" text-xs">{ele}</h6>
                    </div>
                  ),
                )}
              </div>
              {getSkillsBySprintCode(sprint.sprintCode)?.projects?.length >
              0 ? (
                <div
                  className={cn(
                    'inline-flex flex-wrap items-center gap-x-2 gap-y-2',
                    'rounded-[8px] border border-[#07634C] bg-[#2DF8C5] p-1 px-3 text-[#2DF8C5] md:border-2',
                  )}
                >
                  <div>
                    <h3 className="text-[12px] font-bold text-[#004246]">
                      Projects Covered :
                    </h3>
                  </div>
                  {getSkillsBySprintCode(sprint.sprintCode)?.projects?.map(
                    (ele, idx) => (
                      <div
                        key={idx}
                        className="rounded-[5px] border border-[#02BF91] bg-[#07634C] p-1 px-3 text-[#2DF8C5] md:border-2"
                      >
                        <h6 className=" text-xs">{ele}</h6>
                      </div>
                    ),
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CurriculumSprintCard;
