import CurriculumSprintCardWrapper from '@components/CurriculumSprintCard/CurriculumSprintCardWrapper';
import CurriculumSprintCard from '@components/CurriculumSprintCard/index';
import React from 'react';

const data = {
  advancedCurriculumFSD: [],
  basicCurriculumFSD: [
    {
      description:
        '<p>For learners familiar with DSA and Javascript basics, aspiring to be Full stack MERN developers.<br/>Build <strong>4 Work-like Projects and 15+ Micro skilling projects</strong> in real work-like environment.</p>',
      duration: '6 Months',
      image: 'Two.png',
      cards: [
        {
          description: '',
          title: 'Intermediate DSA',
          subCards: [
            {
              description: (
                <CurriculumSprintCardWrapper>
                  <CurriculumSprintCard
                    sprints={[
                      {
                        sprintCode: 'DSA-JS-102',
                      },
                    ]}
                  />
                </CurriculumSprintCardWrapper>
              ),
              title: '',
              skills: [],
              spanToWidth: true,
            },
            {
              description: (
                <CurriculumSprintCardWrapper>
                  <CurriculumSprintCard
                    sprints={[
                      {
                        sprintCode: 'DSA-201',
                      },
                    ]}
                  />
                </CurriculumSprintCardWrapper>
              ),
              title: '',
              skills: [],
              spanToWidth: true,
            },
          ],
        },
        {
          description: '',
          title: 'Frontend - HTML/CSS/JS',
          subCards: [
            {
              description: (
                <CurriculumSprintCardWrapper>
                  <CurriculumSprintCard
                    sprints={[
                      {
                        sprintCode: 'FE-101',
                      },
                    ]}
                  />
                </CurriculumSprintCardWrapper>
              ),
              title: '',
              skills: [],
              spanToWidth: true,
            },
            {
              description: (
                <CurriculumSprintCardWrapper>
                  <CurriculumSprintCard
                    sprints={[
                      {
                        sprintCode: 'FE-102',
                      },
                    ]}
                  />
                </CurriculumSprintCardWrapper>
              ),
              title: '',
              skills: [],
              spanToWidth: true,
            },
            {
              description: (
                <CurriculumSprintCardWrapper>
                  <CurriculumSprintCard
                    sprints={[
                      {
                        sprintCode: 'FE-103',
                      },
                    ]}
                  />
                </CurriculumSprintCardWrapper>
              ),
              title: '',
              skills: [],
              spanToWidth: true,
            },
          ],
        },
        {
          description: '',
          title: 'Frontend (React)',
          subCards: [
            {
              description: (
                <CurriculumSprintCardWrapper>
                  <CurriculumSprintCard
                    sprints={[
                      {
                        sprintCode: 'FE-201',
                      },
                    ]}
                  />
                </CurriculumSprintCardWrapper>
              ),
              title: '',
              skills: [],
              spanToWidth: true,
            },
            {
              description: (
                <CurriculumSprintCardWrapper>
                  <CurriculumSprintCard
                    sprints={[
                      {
                        sprintCode: 'FE-202',
                      },
                    ]}
                  />
                </CurriculumSprintCardWrapper>
              ),
              title: '',
              skills: [],
              spanToWidth: true,
            },
          ],
        },
        {
          description: '',
          title: 'Backend Node',
          subCards: [
            {
              description: (
                <CurriculumSprintCardWrapper>
                  <CurriculumSprintCard
                    sprints={[
                      {
                        sprintCode: 'NODE-201',
                      },
                    ]}
                  />
                </CurriculumSprintCardWrapper>
              ),
              title: '',
              skills: [],
              spanToWidth: true,
            },
            {
              description: (
                <CurriculumSprintCardWrapper>
                  <CurriculumSprintCard
                    sprints={[
                      {
                        sprintCode: 'NODE-202',
                      },
                    ]}
                  />
                </CurriculumSprintCardWrapper>
              ),
              title: '',
              skills: [],
              spanToWidth: true,
            },
          ],
        },
        {
          description: '',
          title: 'Frontend Design',
          subCards: [
            {
              description: (
                <CurriculumSprintCardWrapper>
                  <CurriculumSprintCard
                    sprints={[
                      {
                        sprintCode: 'FE-301',
                      },
                    ]}
                  />
                </CurriculumSprintCardWrapper>
              ),
              title: '',
              skills: [],
              spanToWidth: true,
            },
            {
              description: (
                <CurriculumSprintCardWrapper>
                  <CurriculumSprintCard
                    sprints={[
                      {
                        sprintCode: 'FE-302',
                      },
                    ]}
                  />
                </CurriculumSprintCardWrapper>
              ),
              title: '',
              skills: [],
              spanToWidth: true,
            },
          ],
        },
        {
          description: '',
          title: 'Advanced DSA',
          subCards: [
            {
              description: (
                <CurriculumSprintCardWrapper>
                  <CurriculumSprintCard
                    sprints={[
                      {
                        sprintCode: 'DSA-202',
                      },
                    ]}
                  />
                </CurriculumSprintCardWrapper>
              ),
              title: '',
              skills: [],
              spanToWidth: true,
            },
          ],
        },
      ],
      title: 'MERN Developer Pathway*',
    },
    {
      description:
        '<p>For learners familiar with DSA basics, aspiring to become Java Developers.<br/>Build <strong>4 Work-like Projects and 15+ Micro skilling projects</strong> in real work-like environment.</p>',
      duration: '6 Months',
      image: 'Two.png',
      cards: [
        {
          description: '',
          title: 'Intermediate DSA',
          subCards: [
            {
              description: (
                <CurriculumSprintCardWrapper>
                  <CurriculumSprintCard
                    sprints={[
                      {
                        sprintCode: 'DSA-102',
                      },
                    ]}
                  />
                </CurriculumSprintCardWrapper>
              ),
              title: '',
              skills: [],
              spanToWidth: true,
            },
            {
              description: (
                <CurriculumSprintCardWrapper>
                  <CurriculumSprintCard
                    sprints={[
                      {
                        sprintCode: 'DSA-201',
                      },
                    ]}
                  />
                </CurriculumSprintCardWrapper>
              ),
              title: '',
              skills: [],
              spanToWidth: true,
            },
          ],
        },
        {
          description: '',
          title: 'Core Java',
          subCards: [
            {
              description: (
                <CurriculumSprintCardWrapper>
                  <CurriculumSprintCard
                    sprints={[
                      {
                        sprintCode: 'JAVA-111',
                      },
                    ]}
                  />
                </CurriculumSprintCardWrapper>
              ),
              title: '',
              skills: [],
              spanToWidth: true,
            },
            {
              description: (
                <CurriculumSprintCardWrapper>
                  <CurriculumSprintCard
                    sprints={[
                      {
                        sprintCode: 'JAVA-112',
                      },
                    ]}
                  />
                </CurriculumSprintCardWrapper>
              ),
              title: '',
              skills: [],
              spanToWidth: true,
            },
            {
              description: (
                <CurriculumSprintCardWrapper>
                  <CurriculumSprintCard
                    sprints={[
                      {
                        sprintCode: 'JAVA-201',
                      },
                    ]}
                  />
                </CurriculumSprintCardWrapper>
              ),
              title: '',
              skills: [],
              spanToWidth: true,
            },
            {
              description: (
                <CurriculumSprintCardWrapper>
                  <CurriculumSprintCard
                    sprints={[
                      {
                        sprintCode: 'JAVA-202',
                      },
                    ]}
                  />
                </CurriculumSprintCardWrapper>
              ),
              title: '',
              skills: [],
              spanToWidth: true,
            },
            {
              description: (
                <CurriculumSprintCardWrapper>
                  <CurriculumSprintCard
                    sprints={[
                      {
                        sprintCode: 'JAVA-203',
                      },
                    ]}
                  />
                </CurriculumSprintCardWrapper>
              ),
              title: '',
              skills: [],
              spanToWidth: true,
            },
          ],
        },
        {
          description: '',
          title: 'SpringBoot',
          subCards: [
            {
              description: (
                <CurriculumSprintCardWrapper>
                  <CurriculumSprintCard
                    sprints={[
                      {
                        sprintCode: 'JAVA-301',
                      },
                    ]}
                  />
                </CurriculumSprintCardWrapper>
              ),
              title: '',
              skills: [],
              spanToWidth: true,
            },
            {
              description: (
                <CurriculumSprintCardWrapper>
                  <CurriculumSprintCard
                    sprints={[
                      {
                        sprintCode: 'JAVA-302',
                      },
                    ]}
                  />
                </CurriculumSprintCardWrapper>
              ),
              title: '',
              skills: [],
              spanToWidth: true,
            },
          ],
        },
        {
          description: '',
          title: 'High Level Design Basics',
          subCards: [
            {
              description: (
                <CurriculumSprintCardWrapper>
                  <CurriculumSprintCard
                    sprints={[
                      {
                        sprintCode: 'HLD-101',
                      },
                    ]}
                  />
                </CurriculumSprintCardWrapper>
              ),
              title: '',
              skills: [],
              spanToWidth: true,
            },
          ],
        },
        {
          description: '',
          title: 'Advanced DSA',
          subCards: [
            {
              description: (
                <CurriculumSprintCardWrapper>
                  <CurriculumSprintCard
                    sprints={[
                      {
                        sprintCode: 'DSA-202',
                      },
                    ]}
                  />
                </CurriculumSprintCardWrapper>
              ),
              title: '',
              skills: [],
              spanToWidth: true,
            },
          ],
        },
      ],
      title: 'JAVA Developer Pathway*',
    },
  ],
};

export default data;
