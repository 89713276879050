import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const SaleMiddleStrip = ({ children, data }) => {
  const { title, highlightText, subtitle } = data;

  return (
    <div className="mb-8 h-full w-full max-w-[430px] rounded-[25px] bg-sale-blue-300 md:mb-0 md:max-h-[300px] md:max-w-[1000px]">
      <div className="hidden gap-4 md:flex">
        <div className="h-[300px] w-[309px]  overflow-hidden rounded-[25px]">
          <StaticImage
            loading="eager"
            src="../../../images/v5/Sale/MiddleStrip-Desktop.png"
            placeholder="none"
            alt="Sale Badge"
            width={500}
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
        <div className="m-8 flex flex-col items-start justify-center gap-4 font-manrope">
          <h2 className="text-4xl font-bold text-sale-orange-200">{title}</h2>
          <p className="text-xl font-black text-white">{subtitle}</p>
          {children}
        </div>
      </div>
      <div className="md:hidden">
        <div className="mx-8 mt-12 mb-8 flex flex-col items-center justify-center gap-4 text-center font-manrope">
          <h2 className="capitalise text-base font-bold text-sale-orange-200">
            {title}
          </h2>
          <p className="text-md font-black text-white">{subtitle}</p>
          {children}
        </div>
        <div className="-mb-4 w-full overflow-hidden rounded-[25px]">
          <StaticImage
            loading="lazy"
            src="../../../images/v5/Sale/MiddleStrip-Mobile.png"
            placeholder="none"
            alt="Sale Badge"
            width={500}
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
      </div>
    </div>
  );
};

export default SaleMiddleStrip;
